var vdViagem = {
	Init: function(container) {
		// Funções de binding do exclusivas do projeto
		vdViagem.FormBindings(container);
	},
	IsMobile: psLib.IsMobile,
	IsTablet: psLib.IsTablet,
	DefaultLinks: {
		CondicoesGerais: "http://www.portoseguro.com.br/seguros/seguro-viagem/linha-de-produtos/condicoes-gerais",
		CondicoesGeraisAventura: "http://www.portoseguro.com.br/static-files/Institucional/Documento/SeguroViagem/Seguro%20Viagem%20-%20Processo%20SUSEP%20n%C2%BA%2015414.9004762015-42%20%E2%80%93%2029082015%20%E2%80%93%20Atual.pdf",
		ManualAssistencias: "http://www.portoseguro.com.br/seguros/seguro-viagem/linha-de-produtos/manuais-de-assistencia",
		PoliticaPrivacidade: "https://institucional.portoseguro.com.br/lib/auto-online/politica-privacidade.html",
		Ouvidoria: "http://www.portoseguro.com.br/a-porto-seguro/fale-com-a-porto-seguro/ouvidoria",
		Cancelamento: "https://wwws.portoseguro.com.br/vendaonline/cancelamento.ns",
		GuiaBolso: "http://www.embratel.com.br/Embratel02/files/secao/03/13/317/guiadebolso.pdf",
		vdoLinkChat: "https://www14.directtalk.com.br/chat31/?idd=419700115CAFD00260D5"
	},
	DefaultID: {
		topCorretor: "#vdo-skin-topCorretor",
		lpSeoh1: "#vdo-skin-lpTop-h1",
		lpSeoh2: "#vdo-skin-lpCoverage-h2",
		lpSeoh2Title0: "#vdo-skin-lpCoverage-h2-title0",
		lpSeoh2Desc0: "#vdo-skin-lpCoverage-h2-info",
		lpSeoh3Title0: "#vdo-skin-lpCoverage-h3-title0",
		lpSeoh3Title1: "#vdo-skin-lpCoverage-h3-title1",
		lpSeoh4Title0: "#vdo-skin-lpCoverage-h4-title0",
		lpSeoh4Desc0: "#vdo-skin-lpCoverage-h4-desc0",
		lpSeoh4Title1: "#vdo-skin-lpCoverage-h4-title1",
		lpSeoh4Desc1: "#vdo-skin-lpCoverage-h4-desc1",
		lpSeoh4Title2: "#vdo-skin-lpCoverage-h4-title2",
		lpSeoh4Desc2: "#vdo-skin-lpCoverage-h4-desc2",
		lpSeoh4Title3: "#vdo-skin-lpCoverage-h4-title3",
		lpSeoh4Desc3: "#vdo-skin-lpCoverage-h4-desc3",
		lpSeoh3BenefitsTitle0: "#vdo-skin-lpBenefits-h3-title0",
		lpSeoh3BenefitsTitle1: "#vdo-skin-lpBenefits-h3-title1",
		lpSeoh4BenefitsTitle0: "#vdo-skin-lpBenefits-h4-title0",
		lpSeoh4BenefitsDesc0: "#vdo-skin-lpBenefits-h4-desc0",
		lpSeoh4BenefitsTitle1: "#vdo-skin-lpBenefits-h4-title1",
		lpSeoh4BenefitsDesc1: "#vdo-skin-lpBenefits-h4-desc1",
		lpSeoh4BenefitsTitle2: "#vdo-skin-lpBenefits-h4-title2",
		lpSeoh4BenefitsDesc2: "#vdo-skin-lpBenefits-h4-desc2",
		lpSeoh3PortoCardTitle0: "#vdo-skin-lpPortoCard-h3-title0",
		lpSeoh4PortoCardDesc0: "#vdo-skin-lpPortoCard-h4-desc0",
		lpSeoh3PlansTitle0: "#vdo-skin-lpPlans-h3-title0",
		lpSeoh3PlansTitle1: "#vdo-skin-lpPlans-h3-title1",
		lpSeoh4PlansTitle0: "#vdo-skin-lpPlans-h4-title0",
		lpSeoh4PlansTitle1: "#vdo-skin-lpPlans-h4-title1",
		lpSeoh4PlansTitle2: "#vdo-skin-lpPlans-h4-title2",
		lpSeoh4PlansTitle3: "#vdo-skin-lpPlans-h4-title3",
		lpSeoh4PlansTitle4: "#vdo-skin-lpPlans-h4-title4",
		lpSeoh4PlansDesc0: "#vdo-skin-lpPlans-h4-desc0",
		lpSeoh4PlansDesc1: "#vdo-skin-lpPlans-h4-desc1",
		lpSeoh4PlansDesc2: "#vdo-skin-lpPlans-h4-desc2",
		lpSeoh4PlansDesc3: "#vdo-skin-lpPlans-h4-desc3",
		lpSeoh4PlansDesc4: "#vdo-skin-lpPlans-h4-desc4",
		lpSeoh3FaqTitle: "#vdo-skin-lpFaq-h3",
		lpSeoh4FaqTitle0: "#vdo-skin-lpFaq-h4-title0",
		lpSeoh4FaqTitle1: "#vdo-skin-lpFaq-h4-title1",
		lpSeoh4FaqTitle2: "#vdo-skin-lpFaq-h4-title2",
		lpSeoh4FaqTitle3: "#vdo-skin-lpFaq-h4-title3",
		lpSeoh4FaqTitle4: "#vdo-skin-lpFaq-h4-title4",
		lpSeoh4FaqDesc0: "#vdo-skin-lpFaq-h4-desc0",
		lpSeoh4FaqDesc1: "#vdo-skin-lpFaq-h4-desc1",
		lpSeoh4FaqDesc2: "#vdo-skin-lpFaq-h4-desc2",
		lpSeoh4FaqDesc3: "#vdo-skin-lpFaq-h4-desc3",
		lpSeoh4FaqDesc4: "#vdo-skin-lpFaq-h4-desc4",
		lpTopLegalText0: "#vdo-legalText-lpTop-coverage0",
		lpTopLegalText1: "#vdo-legalText-lpTop-coverage1",
		lpPromoText0: "#vdo-skin-lpPromo-h2",
		lpPromoText1: "#vdo-skin-lpPromo-h3",
		lpFooterLegalText0: "#vdo-legalTexts-lpFooter-asterisks0",
		lpFooterLegalText1: "#vdo-legalTexts-lpFooter-asterisks1",
		lpFooterLegalText2: "#vdo-legalTexts-lpFooter-asterisks2",
		lpFooterLegalText3: "#vdo-legalTexts-lpFooter-asterisks3",
		footerLegalText0: "#vdo-legalTexts-footer-desc0",
		footerLegalText1: "#vdo-legalTexts-footer-desc1",
		footerLegalText2: "#vdo-legalTexts-footer-desc2",
		footerProcon: "#vdo-legalTexts-footer-procon-new",
		footerLinks0: "#vdo-skin-politic",
		footerLinks1: "#vdo-footer-links1",
		footerLinks2: "#vdo-footer-links2",
		footerLinks3: "#vdo-footer-links3",
		footerLinks4: "#vdo-footer-links6",
		vdoCalcInfo0: "#vdo-legalTexts-calc-desc0",
		vdoCalcInfo1: "#vdo-legalTexts-calc-desc1",
		vdoCalcInfo2: "#vdo-legalTexts-calc-desc2",
		vdoAcceptedTermDesc0: "#vdo-legalTexts-acceptedTerm-desc0",
		vdoAcceptedTermDesc1: "#vdo-legalTexts-acceptedTerm-desc1",
		vdoAcceptedTermDesc2: "#vdo-legalTexts-acceptedTerm-desc2",
		vdoAcceptedTermDesc3: "#vdo-legalTexts-acceptedTerm-desc3",
		vdoAcceptedTermDesc4: "#vdo-legalTexts-acceptedTerm-desc4",
		vdoCondicoesGeraisSeguroIndividual: "#vdo-pdf-seguroIndividual",
		vdoCondicoesGeraisSeguroIndividualAventura: "#vdo-pdf-seguroIndividualAventura",
		vdoLinkChat: "#vdo-linkChat",
		zipcodeSearchVoid: ".calc-cepNoResult-proponente",
		loginPorto: "#isLoginHabilitado"
	},
	IsTopoCorretor: false,
	CheckTopoCorretor: function(param) {
		//if(typeof param.calculo.corretor != "undefined")
		if($(vdViagem.DefaultID.topCorretor).length > 0)
			vdViagem.IsTopoCorretor = true;
	},
	IsLoginPorto: false,
	CheckLoginPorto: function(param) {
		if($(vdViagem.DefaultID.loginPorto).val() != "false"  && $(vdViagem.DefaultID.loginPorto).length > 0)
			vdViagem.IsLoginPorto = true;
	},
	TplBuildFooter: function(staticPath) {
		var rtn = "";

		rtn += '<div class="ps-footer vd-foot">\
					<div class="ps-container">\
						<div class="ps-footer-features">\
							<div>\
								<strong>\
									<span class="ps-ico ps-ico-wallet ps-ico-sm"></span>Formas de <span>Pagamento</span>\
								</strong>\
								<p>Voc&ecirc; pode pagar seu Seguro Viagem utilizando os seguintes cart&otilde;es de cr&eacute;dito:</p>\
								<picture>\
									<source type="image/webp" srcset="'+staticPath+'/logo-cc-accepted-foot.webp">\
									<img src="'+staticPath+'/logo-cc-accepted-foot.png" alt="Mastercard, Visa e Elo" width="260" height="45">\
								</picture>\
							</div>\
							<div>\
								<strong>\
									<span class="ps-ico ps-ico-chat ps-ico-sm"></span>Site <span>Seguro</span>\
								</strong>\
								<p>P&aacute;gina criptografada Privacidade e seguran&ccedil;a para voc&ecirc;.</p>\
							</div>\
							<div>\
								<strong>\
									<span class="ps-ico ps-ico-call-center ps-ico-sm"></span>Central de <span>Atendimento</span>\
								</strong>\
								<p>Para atendimento emergencial no Brasil e/ou Exterior durante a viagem:<br/> (11) 3366-3330 - 24 horas<br/>(atende liga&ccedil;&otilde;es a cobrar)<br/><br/>Para informa&ccedil;&otilde;es sobre o seguro viagem, coberturas, cancelamento e outros assuntos:<br/>3366-3377 - Grande S&atilde;o Paulo<br/>0800 727 9393 - Demais localidades</p>\
							</div>\
							<div>\
								<span class="ps-logo ps-logo-white"></span>\
							</div>\
						</div>\
						<div class="ps-footer-links">\
							<div>\
								&copy; <span class="ps-currentYear">2022</span> Porto Seguro &bull; Todos os direitos reservados.\
							</div>\
							<ul class="ps-list">\
								<li><a href="'+vdViagem.FillContent(vdViagem.DefaultID.footerLinks0,"",true)+'" target="_blank" rel="noopener">Pol&iacute;tica de privacidade</a></li>\
								<li><a href="'+vdViagem.FillContent(vdViagem.DefaultID.footerLinks1,"",true)+'" target="_blank" rel="noopener">Ouvidoria</a></li>\
								<li><a href="'+vdViagem.FillContent(vdViagem.DefaultID.footerLinks2,"",true)+'" target="_blank" rel="noopener">Solicita&ccedil;&atilde;o de cancelamento</a></li>\
								<li><a href="'+vdViagem.FillContent(vdViagem.DefaultID.footerLinks4,"",true)+'" target="_blank" rel="noopener">Mapa do site</a></li>\
								<li><a href="javascript:;" id="ot-sdk-btn" class="vo-lgpd-cookies ot-sdk-show-settings" rel="noindex nofollow noopener">Configura&ccedil;&otilde;es de Cookies</a></li>\
							</ul>\
						</div>\
					</div>\
				</div>';

		return rtn;
	},
	TplBuildModal: function(modal,param) {
		if(typeof param == "undefined") param = {};

		var rtn = '';

		if(modal == "PortoViajeBem") {
			rtn += '<div id="PortoViajeBem" class="ps-modal">';
			rtn += '	<a href="javascript:;" class="ps-modal-close ps-modal-close-default"><span class="ps-ico ps-ico-sm ps-sm-ico-lg ps-ico-close"></span></a>';
			rtn += '	<div class="ps-modal-container ps-lg-modal-medium">';
			rtn += '		<div class="vd-modal-content video">';
			rtn += '			<iframe name="src" width="560" height="315" src="//www.youtube.com/embed/yWw00sOY6yI?rel=0" frameborder="0" allowfullscreen></iframe>';
			rtn += '		</div>';
			rtn += '	</div>';
			rtn += '</div>';
		} else if(modal == "ModalDetalhePlano") {
			rtn += '<div id="ModalDetalhePlano-'+param.id+'" class="ps-modal">';
			rtn += '	<a href="javascript:;" class="ps-modal-close ps-modal-close-default"><span class="ps-ico ps-ico-sm ps-sm-ico-lg ps-ico-close"></span></a>\
						<div class="ps-modal-container ps-lg-modal-large">\
							<div class="ps-modal-title">\
								<h2 class="ps-title--xs ps-md-title--sm">'+param.plano+'</h2>\
								<h3 class="ps-title--xs ps-md-title--sm">R$ '+frmLib.FormFormatCurrency(param.valor)+'</h3>\
							</div>\
							<div class="ps-modal-content">\
								<div class="vdo_checkout__box">\
									<dl>\
										'+param.body+'\
									</dd>\
								</div>\
							</div>\
						</div>\
					</div>';
		} else if(modal == "ModalRtnEnviarCotacaoMail") {
			rtn += '<div id="ModalRtnEnviarCotacaoMail" class="vd-modal">';
			rtn += '	<a href="javascript:;" class="vd-modalClose"><span class="vd-icon vd-md vd-closeRounded-md-blue"></span></a>';
			rtn += '	<div class="vd-modal-content vd-modal-small">';
			rtn += '		<iframe name="src" width="100%" height="315" src="about:blank" frameborder="0" allowfullscreen></iframe>';
			rtn += '	</div>';
			rtn += '</div>';
		} else if(modal == "ModalResumoSeguro") {
			var idx = param.proposta.indicePacoteEscolhido-1,
				c = param.retornoCalculo.listaPacotesContratacao[idx];

			rtn += '<div id="ModalResumoSeguro" class="ps-modal">\
						<a href="javascript:;" class="ps-modal-close ps-modal-close-default"><span class="ps-ico ps-ico-sm ps-sm-ico-lg ps-ico-close"></span></a>\
						<div class="ps-modal-container">\
							<div class="ps-modal-title">\
								<h2 class="ps-title--xs ps-md-title--sm">'+param.proposta.nomePacoteEscolhido+'</h2>\
								<h3 class="ps-title--xs ps-md-title--sm">R$ '+frmLib.FormFormatCurrency(param.proposta.valorTotalPacoteEscolhido)+'</h3>\
							</div>\
							<div class="ps-modal-content">\
								<div class="vdo_checkout__box">\
									<span class="ps-text-overline--sm">Coberturas</span>';
		rtn += '					<dl>';
									for(var i=0,l=c.listaDescricaoBeneficios.length;i<l;i++) {
		rtn += '						<dt>'+c.listaDescricaoBeneficios[i].descricao+'</dt>';
		rtn += '						<dd>'+c.listaDescricaoBeneficios[i].valor+'</dd>';
									}
		rtn += '					</dl>\
								</div>\
							</div>\
						</div>\
					</div>';
		} else if(modal == "ModalLogin") {
			rtn += '<div id="ModalLogin" class="ps-modal">\
						<a href="javascript:;" class="ps-modal-close ps-modal-close-default"><span class="ps-ico ps-ico-sm ps-sm-ico-lg ps-ico-close"></span></a>\
						<div class="ps-modal-container">\
							<div class="ps-modal-content">\
								<div class="vd-row">\
									<div class="vd-mod12">\
										<h2>Agora, voc&ecirc; ser&aacute; direcionado para a tela de login/cadastro</h2>\
										<p>\
											Para seguir com a contrata&ccedil;&atilde;o, voc&ecirc; precisa fazer o login no portal do cliente.\
										</p>\
										<p>\
											Se voc&ecirc; ainda n&atilde;o &eacute; cadastrado, fique tranquilo! &Eacute; s&oacute; clicar em &quot;Quero me cadastrar&rdquo; e criar sua conta.\
										</p>\
									</div>\
								</div>\
								<div class="vd-row vd-submitRow vd-center">\
									<div class="vd-mod8 vd-lspan2">\
										<a href="javascript:;" class="vd-btn ps-modal-close" data-modal="#ModalLogin"  data-exec="click:abreModalLogin();">Ir para o login/cadastro</a>\
									</div>\
								</div>\
							</div>\
						</div>\
					</div>';
		} else if(modal == "ModalPlanoEuropa") {
			rtn += '<div id="ModalPlanoEuropa" class="ps-modal">\
						<a href="javascript:;" class="ps-modal-close ps-modal-close-default"><span class="ps-ico ps-ico-sm ps-sm-ico-lg ps-ico-close"></span></a>\
						<div class="ps-modal-container ps-lg-modal-medium">\
							<div class="ps-modal-title">\
								Pa&iacute;ses que fazem parte do Tratado de Schengen:\
							</div>\
							<div class="ps-modal-content">\
								<p>Alemanha, &Aacute;ustria, B&eacute;lgica, Dinamarca, Eslov&aacute;quia, Eslov&ecirc;nia, Espanha, Est&ocirc;nia, Finl&acirc;ndia, Fran&ccedil;a, Gr&eacute;cia, Holanda, Hungria, Isl&acirc;ndia, It&aacute;lia, Let&ocirc;nia, Litu&acirc;nia, Luxemburgo, Malta, Noruega, Pol&ocirc;nia, Portugal, Rep&uacute;blica Tcheca, Su&eacute;cia e Su&iacute;&ccedil;a.</p>\
							</div>\
						</div>\
					</div>';
		}

		return rtn;
	},
	TplHeader: function(step,param) {
		var rtn = '';

		rtn += '<div class="ps-mod8 ps-sm-mod12 vd-sm-sessionHide vd-md-sessionHide vd-top--broker">\
					<span class="ps-logo '+(step == "home" ? "ps-logo-white" : "")+'">Porto Seguro</span>\
					'+vdViagem.TplBrokerData(param)+'\
				</div>';

		return rtn;
	},
	TplBrokerData: function(param) {
		var getParams = (new URL(document.location)).searchParams,
			testSusep = getParams.get("susepTestSkin");

		if(typeof param.calculo.codigoAtendimento == "undefined") { // está na home
			if(testSusep != null) {
				window.sessionStorage.setItem("susepTestSkin",testSusep);
			} else {
				window.sessionStorage.removeItem("susepTestSkin");
			}			
		} else { // demais páginas
			testSusep = window.sessionStorage.getItem("susepTestSkin");
		}
		console.log(window.sessionStorage.getItem("susepTestSkin"));

		var ddd = param.calculo.corretor.telefoneCorretorVendaOnline.numeroDddTelefoneCorretor,
			tel = param.calculo.corretor.telefoneCorretorVendaOnline.numeroTelefoneCorretor;

		tel = tel.toString();
		if(ddd == 800) {
			tel = "0"+ddd+" "+tel.substr(0,3)+" "+tel.substr(4,4);
			ddd = 0;
		} else {
			tel = tel.length > 8 ? tel.substr(0,5)+"-"+tel.substr(5,4) : tel.substr(0,4)+"-"+tel.substr(4,4);
		}

		
		var brokerInfo = {
			susep: testSusep == null ? param.calculo.corretor.susepWeb : testSusep,
			name: param.calculo.corretor.nome,
			phone: ddd == 0 ? tel : '(' + ddd + ') ' + tel
		};

		rtn = '\
			<div class="vd-corretorData" data-susep="'+brokerInfo.susep+'">\
				<div class="vd-corretorLogo" data-susep="'+brokerInfo.susep+'">\
					<h1>'+brokerInfo.name+'</h1>\
					<div>\
						<span class="vd-icon vd-sm vd-phone-black-24"></span>\
						<span id="vd-corretorPhone">'+brokerInfo.phone+'</span>\
					</div>\
				</div>\
			</div>';

		return rtn;
	},
	TplCheckoutHeader: function(back,step,param) {
		var rtn = ''; 

			rtn += '<div class="vd-top vd-top--checkout">\
						<div class="ps-container">';
			rtn += '		<div class="ps-row">';
			rtn += '			<div class="ps-mod2 ps-sm-mod3 ps-text-align--left">\
									<a href="javascript:;" class="vd-calcBack vdo_checkout__steps--back" title="Voltar" data-trigger="click:#ps-frm-entry-back">\
										<span class="vd-ico-back"></span>\
									</a>\
								</div>';

								if(vdViagem.IsTopoCorretor || window.skinBroker == "undefined") {
									//rtn += vdViagem.TplHeader("checkout",param);
									rtn += '\
										<div class="ps-mod7 ps-sm-mod6 ps-sm-lspan3 vd-top--broker">\
											<span class="ps-logo">Porto Seguro</span>\
											'+vdViagem.TplBrokerData(param)+'\
										</div>';
								} else {
									rtn += '\
										<div class="ps-mod7 ps-sm-mod9 ps-text-align--right">\
											<span class="ps-logo">Porto Seguro</span>\
										</div>';
								}
			
			rtn += '		</div>\
						</div>\
					</div>';

		return rtn;
	},
	TplCheckoutHeaderSteps: function(step) {
		let rtn = '';

		rtn += '<div class="vdo_checkout__steps '+(step == 5 ? 'ps-hide' : '')+'">\
					<div class="ps-container">\
						<div class="ps-row">\
							<div class="ps-mod8 ps-sm-mod8 ps-sm-lspan2">\
								<ul class="ps-wizard ps-wizard-icon">\
									<li class="ps-wizard-step '+(step == 1 ? ' ps-wizard-step-selected' : (step > 1 ? ' ps-wizard-step-after' : ''))+'"><a href="javascript:;"><span class="ps-ico ps-glyph ps-ico-plane"></span> <span class="ps-hide ps-sm-show">Detalhes da viagem</span></a></li>\
									<li class="ps-wizard-step '+(step == 2 ? ' ps-wizard-step-selected' : (step > 2 ? ' ps-wizard-step-after' : ''))+'"><a href="javascript:;"><span class="ps-ico ps-glyph ps-ico-consulting"></span> <span class="ps-hide ps-sm-show">Planos</span></a></li>\
									<li class="ps-wizard-step '+(step == 3 ? ' ps-wizard-step-selected' : (step > 3 ? ' ps-wizard-step-after' : ''))+'"><a href="javascript:;"><span class="ps-ico ps-ico-add-user"></span><span class="ps-hide ps-sm-show">Dados do passageiro</span></a></li>\
									<li class="ps-wizard-step '+(step == 4 ? ' ps-wizard-step-selected' : (step > 4 ? ' ps-wizard-step-after' : ''))+'"><a href="javascript:;"><span class="ps-ico ps-ico-check"></span><span class="ps-hide ps-sm-show">Pagamento</span></a></li>\
								</ul>\
							</div>\
						</div>\
					</div>\
				</div>';

		return rtn;

	},
	TplLogin: function(param) {
		var rtn = '';
		//classes de exibição Portocom
		//botaoLoginPorto
		//vdo-login-header-hello logado
		//naoLogado
		//nomeCliente

		rtn += '<div class="botaoLoginPorto">\
                    <div class="vd-login vd-login-logged">\
						<a href="javascript:;" class="vd-login-protocol naoLogado" data-trigger="click:#modalInit">Login / Cadastro</a>\
						<strong class="vdo-login-preheader vdo-login-header-hello logado" style="display: none">\
							<span class="ps-ico ps-ico-man"></span> Ol&aacute;, <span class="nomeCliente"></span> <a href="javascript:;" data-trigger="click:.sair-login">Sair</a>\
						</strong>\
					</div>\
                </div>';
	    return rtn;
	},
	TplBuildCheckoutBar: function(param) {
		var obj = param,
			rtn = '';

			rtn += '<div class="vdo_checkout__bar">\
				<ul class="ps-list">\
					<li>\
						<div>\
							Plano contratado\
							<br>\
							<strong>'+obj.proposta.nomePacoteEscolhido+'</strong>\
							<br>\
							<a href="javascript:;" class="ps-open-modal" data-modal="#ModalResumoSeguro">Ver detalhes</a> | \
							<a href="javascript:;" class="ps-color-red" data-trigger="click:#ps-frm-entry-back">Mudar plano</a>\
						</div>\
					</li>\
					<li>\
						<div>\
							Valor do seguro: \
							<br>\
							<strong>R$ '+frmLib.FormFormatCurrency(obj.proposta.valorTotalPacoteEscolhido)+'</strong>\
						</div>\
					</li>\
					<li class="ps-hide ps-sm-show">\
						<div>\
							Per&iacute;odo da viagem\
							<br>\
							<strong>'+obj.calculo.dataSaidaProponente+' a '+obj.calculo.dataRetornoProponente+'</strong>\
						</div>\
					</li>\
					<li class="ps-hide ps-sm-show">\
						<div>\
							Protocolo\
							<br>\
							<strong>'+obj.codigoAtendimento+'</strong>\
						</div>\
					</li>\
				</ul>\
			</div>';

			return rtn;
	},
	FormBindings: function(container) {
		if(typeof container == "undefined") container = "body";

		$(".vd-showModal",container).on("click",function() {
			var t = $(this),
				src = t.data("src"),
				target = t.data("ref");

			if(typeof target != "undefined") {
				vdViagem.TplBuildModal(target,src);
			}
		});

		$(".vd-modalClose",container).on("click",function() {
			var t = $(this).parent();

			t.removeClass("vd-visible");
			if(t.find("iframe").length > 0)
				t.find("iframe").attr("src","about:blank");

			$("body").css("overflow","visible");
			setTimeout(function() {t.hide()},200);
		});

		$(".vd-scrollTo").on("click",function() {
			var to = $(this).data("scrollto");

			if(typeof to !== "undefined") {
				var pos = $(to).offset();

				$("html,body").animate({
					scrollTop: pos.top
				},500);
			}
		});
	},
	GetVar: function() {
		var rtn  = {};
		var decode = function (s) { return decodeURIComponent(s.replace(/\+/g, " ")); };
		var queryString = location.search.substring(1); 
		var keyValues = queryString.split('&'); 

		for(var i=0,l=keyValues.length;i<l;i++) { 
			var key = keyValues[i].split('=');
			if (key.length > 1) {
				rtn[decode(key[0])] = decode(key[1]);
			}
		} 

		return rtn; 
	},
	FillContent: function(ctt,container,type) {
		if(container == "undefined") return;
		if(type == "undefined") type = false;

		if(!type)
			ctt = $(ctt).html();
		else
			ctt = $(ctt).attr("href");
	
		if(!ctt)
			ctt = '';		

		return ctt;
	},
 	SlickSlides: function(container,cfg) {
		$(container).each(function() {
			var t = $(this),
				setCfg = typeof t.data("slickconfig") !== "undefined" ? t.data("slickconfig") : null,
				defaultCfg = cfg;

			if(t.children("div").length < 2) return;

			if(setCfg != null) {
				setCfg = setCfg.replace(/'/g,"\"");
				setCfg = JSON.parse(setCfg);
				$.extend(defaultCfg,setCfg);
			}

			t.slick(defaultCfg);
		});
	}
};